import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDiscoverItemsAPI, getTenant, getTenants } from './helpers';
import { api, getUserName } from '@iblai/ibl-web-react-common';

function useDiscover() {
  const [discoverItems, setDiscoverItems] = useState([]);
  const [discoverLoaded, setDiscoverLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [selectedTerms, setSelectedTerms] = useState(() => {
    const tenants = getTenants();
    if (tenants) {
      return tenants.map((tenant) => `tenant:${tenant}`);
    }
    return [];
  });

  const queryParam = new URLSearchParams(location.search);
  const search = queryParam.get('q');

  useEffect(() => {
    setDiscoverLoaded(false);
    // getDiscoverItemsAPI(discoverCallback, 1, selectedTerms, search);
    const requestData = {
      limit: 12,
    };
    if (page > 1) {
      requestData['offset'] = page * 12;
    }
    if (search) {
      requestData['query'] = search;
    }
    setDiscoverLoaded(false);
    const username = getUserName();
    if (username) {
      requestData['username'] = username;
      api.ibldmsearch.searchPersonalizedCatalog(
        requestData,
        'courses',
        (_data) => {
          discoverCallback(_data.courses);
        },
        () => setDiscoverLoaded(true)
      );
    } else {
      api.ibldmsearch.searchCatalog(
        requestData,
        'courses',
        (_data) => {
          discoverCallback(_data.courses);
        },
        () => setDiscoverLoaded(true)
      );
    }
  }, [search, page]);

  const discoverCallback = (data) => {
    setDiscoverItems(data);
    setDiscoverLoaded(true);
  };
  return [
    discoverItems,
    discoverLoaded,
    setDiscoverItems,
    setDiscoverLoaded,
    selectedTerms,
    setSelectedTerms,
  ];
}

export default useDiscover;
